import React from 'react'
import Layout from '../layouts'
import { Link } from 'gatsby'
import SubCategoryContent from '../components/categorycontent/subcategorycontent.js'
import SideNavCat from '../components/sidenav'
import get from 'lodash/get'

class SubCategoryPage extends React.Component {
  render() {
    // console.log(this.props.data.strapiSubcategories.subcategories)
    return (
      <Layout
        head={{
          name: this.props.data.strapiSubcategories.name,
          catURL: '/' + this.props.data.strapiSubcategories.url + '/',
        }}
      >
        <div className="page-heading">
          <div className="container">
            <h1>Shop</h1>
          </div>
        </div>
        <div className="shop-path">
          <div className="container">
            <span>
              <Link to="/" title="Home">
                Shop
              </Link>
              &nbsp;
              <i className="fa fa-angle-right" />
              <Link
                to={get(
                  this.props.data,
                  'strapiSubcategories.category.url',
                  'Category url'
                )}
                title={get(
                  this.props.data,
                  'strapiSubcategories.category.name',
                  'Category Name'
                )}
              >
                {get(
                  this.props.data,
                  'strapiSubcategories.category.name',
                  'Category Name'
                )}
              </Link>
              &nbsp;
              <i className="fa fa-angle-right" />
              {this.props.data.strapiSubcategories.name}
            </span>
          </div>
        </div>
        <div className="featured-section category-page page-content">
          <div className="container">
            <div className="row">
              <div className="col-md-4 shop-categories">
                <h4 className="section-heading">Categories</h4>
                <SideNavCat />
              </div>
              <SubCategoryContent
                content={this.props.data.strapiSubcategories}
              />
              <div className="pt-5">
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.data.strapiSubcategories.content,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default SubCategoryPage

export const categorynodeQuery = graphql`
  query($id: String!) {
    strapiSubcategories(id: { eq: $id }) {
      id
      name
      productid
      content
      url
      subcategories {
        name
        url
      }
      category {
        name
        url
      }
    }
  }
`
