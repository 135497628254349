import React from 'react'
// import SideNavCat from '../sidenav'
import ProductListing from '../listings/productlisting'
import CategoryListing from '../listings/subcategorylisting'
// import { StaticQuery, graphql } from 'gatsby'

const SubcategoryContent = ({ content, productlisting }) => (
	<div className="col-md-8 shop-items">
		{(content.subcategories.length > 0 && (
			<CategoryListing listings={content} />
		)) || <ProductListing content={content} />}
	</div>
)

export default SubcategoryContent
